function sliderEquipment() {
    var swiper = new Swiper('.equipment__cards-top .swiper', {
        slidesPerView: 1,
        spaceBetween: 15,
        navigation: {
            nextEl: '.equipment__cards-top .swiper-button-next',
            prevEl: '.equipment__cards-top .swiper-button-prev',
        },
    })
}

function sliderEquipment2() {
    var swiper = new Swiper('.equipment__cards-bottom .swiper', {
        slidesPerView: 1,
        spaceBetween: 15,
        navigation: {
            nextEl: '.equipment__cards-bottom .swiper-button-next',
            prevEl: '.equipment__cards-bottom .swiper-button-prev',
        },
    })
}

function certificatesSlider() {
    var swiper = new Swiper('.certificates .swiper-container', {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        spaceBetween: 30,
        loop: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1.5,
        },
        navigation: {
            nextEl: '.certificates .swiper-button-next',
            prevEl: '.certificates .swiper-button-prev',
        },
    })
}

function exhibitionSlider() {
    var swiper = new Swiper('.exhibition .swiper', {
        slidesPerView: "3",
        spaceBetween: 30,
        breakpoints: {
            1200: {
              slidesPerView: 3
            },
            576: {
                slidesPerView: 2
            },
            320: {
                slidesPerView: 1
            },
        }
    })
}

function sliderReviews() {
    var swiper = new Swiper('.reviews .swiper', {
        slidesPerView: "4",
        spaceBetween: 30,
        navigation: {
            nextEl: '.reviews .swiper-button-next',
            prevEl: '.reviews .swiper-button-prev',
        },
        breakpoints: {
            1200: {
                slidesPerView: "4",
            },
            991: {
                slidesPerView: "3",
            },
            575: {
                spaceBetween: 25,
                slidesPerView: "2",
            },
            450: {
                slidesPerView: "2",
            },
            320: {
                slidesPerView: "1",
            }
        }
    })
}

function gallery() {
    var swiper = new Swiper(".gallery .mySwiper", {
        slidesPerView: 3,
        spaceBetween: 20,
        freeMode: true,
        watchSlidesProgress: true,
        direction: 'vertical',
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            575: {
                direction: 'vertical',
            },
            320: {
                direction: 'horizontal',
                spaceBetween: 10,
            }
        }
    });
    var swiper2 = new Swiper(".gallery .mySwiper2", {
        spaceBetween: 10,
        slidesPerView: 1,
        thumbs: {
            swiper: swiper,
        },
    });
}

$(document).ready(function() {
    sliderEquipment();
    sliderEquipment2()
    gallery();
    certificatesSlider();
    sliderReviews();
    exhibitionSlider();

    $(".header-bottom__link2").hover(onIn, onOut);
    $(".header-bottom__link2 .header-bottom__show").click(function () {
        if ($(this).parents(".header-bottom__link2").hasClass("header-bottom__link2--active")) {
            $(this).parents(".header-bottom__link2").removeClass("header-bottom__link2--active")
        } else {
            $(this).parents(".header-bottom__link2").addClass("header-bottom__link2--active")
        }
    })

    // menu
    $('.header-mob__burger').click(function () {
        $(this).toggleClass('header-mob__burger--active')
        $('.header').toggleClass('header--active')
        $('.header__wrapp').toggleClass('header__wrapp--active')
        $('body').toggleClass('fixed-body')
    })

    var header = $(".header-mob");
    var scrollChange = 1;
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= scrollChange) {
            header.addClass("bg-blue");
        } else {
            header.removeClass("bg-blue");
        }
    });

    // Tabs
    $(".info__tab").click(function () {
        let path = $(this).attr("data-tab-path")
        $(this).siblings(".info__tab").removeClass("info__tab--active")
        $(this).addClass("info__tab--active")

        $(this).parents(".info__tabs").find(".info__content").removeClass("info__content--active")
        $(this).parents(".info__tabs").find(`.info__content[data-content-path="${path}"]`).addClass("info__content--active")
    })

    // Up
    var btn = $('.up');
    $(window).scroll(function() {
        if ($(window).scrollTop() > 300) {
            btn.addClass('up--active');
        } else {
            btn.removeClass('up--active');
        }
    });
    btn.on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop:0}, '300');
    });

    // modal

    $("[data-modal]").click(function () {
        let modalId = $(this).attr("data-modal")

        openModal(modalId)
    })

    function openModal(modalId){
        $(`#${modalId}`).addClass("modal--active")
        $("body").addClass("fixed-body")
    }

    $(".modal").click(function () {
        closeModal()
    })

    $("._close-modal").click(function () {
        closeModal()
    })

    $(".modal__dialog").click(function (e) {
        e.stopPropagation()
    })


    function closeModal() {
        $('.modal').removeClass("modal--active")
        $("body").removeClass("fixed-body")
    }

})

function onIn() {
    if (window.innerWidth > 1200) {
        let el = $(this)
        setTimeout(function() {
            if (el.is(':hover')) {
                console.log(el)
                el.addClass("header-bottom__link2--active")

            }
        }, 200);
    }
}

function onOut() {
    if (window.innerWidth > 1200) {
        $(this).removeClass("header-bottom__link2--active")
    }
}